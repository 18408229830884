import { VIEW_PERMISSIONS } from '../actions/user-preferences-action';
import { UserPreferenceResponse } from '../model/user-preferences.interface';

export function userPreferencesReducer(state: UserPreferenceResponse, action) {
  switch (action.type) {
    case VIEW_PERMISSIONS:
        return action.payload;
    default:
        return state;
    }
}